<template>
  <b-row
      class="m-1"
  >
    <b-col md="12">
      <div class="d-flex align-items-center mt-1">
        <b-avatar
            v-if="person.posterUrl"
            :src="person.posterUrl"
            class="mr-50 cursor-pointer"
            :class="(person.best > 0) ? 'person-avatar' : ''"
            size="lg"
            rounded
            badge-variant="success"
            badge-left
            badge-offset="-0.5em"
            @click.native="viewPoster(person)"
        />
        <b-link :to="{ name: 'person-view', params: { id: person.db_id } }" :disabled="!person.db_id">
          <div class="user-page-info">
            <h6 class="mb-0" :class="!person.db_id ? 'text-warning': ''">
              {{ person.nameRu ? person.nameRu : person.nameEn }}
              <span v-if="person.nameRu && person.nameEn">({{ person.nameEn }})</span>
            </h6>
            <small v-if="person.description" class="text-muted">{{ person.description }}</small>
          </div>
        </b-link>
        <span class="ml-auto" v-if="person.db_id">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon mr-1"
              v-if="false"
              @click="viewPoster(person)"
          >
            <feather-icon icon="MusicIcon"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon"
              v-if="person && !person.best"
              @click="setPersonBest(person.db_id)"
          >
            <feather-icon icon="PlusIcon"/>
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon"
              :disabled="true"
              v-else
          >
            <feather-icon icon="CheckIcon"/>
          </b-button>
        </span>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCol,
  BLink,
  BRow,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import 'animate.css';

export default {
  components: {
    BAvatar,
    BLink,
    BRow,
    BCol,
    BButton,
    BImg,
  },
  directives: {
    Ripple,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    //const person = props.personData
    const setPersonBest = (id) => {
      emit('block')
      store.dispatch('movies/updatePerson', {id : id, data: {best: true}})
          .then(response => {
            emit('refresh')
          })
    }

    return {
      setPersonBest,
      //person,
    }
  },
  methods: {
    viewPoster(person) {
      this.$swal({
        title: person.nameRu,
        // eslint-disable-next-line global-require
        imageUrl: person.posterUrl,
        imageAlt: person.nameRu,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        _buttonsStyling: false,
      })
    },
  }
}
</script>

<style scoped>
.person-avatar {
  border-color: #00cf00;
  border-width: 3px;
  border-style: solid;
}
</style>