<template>
  <content-with-sidebar
      class="cws-container cws-sidebar-right blog-wrapper"
  >

    <!-- content -->
    <div class="blog-detail-wrapper" v-if="movieDetail">
      <b-row>
        <!-- blogs -->
        <b-col cols="12">
          <b-card
              _img-top
              _img-alt="Постер"
              _:title="`${movieDetail.title_ru} / ${movieDetail.title_en} (${movieDetail.year})`"
          >
            <h1 class="d-flex">{{ movieDetail.title_ru }}&nbsp;
              <span v-if="movieDetail.title_en"> / {{ movieDetail.title_en }}&nbsp;</span>
              ({{ movieDetail.year }})
              <div class="d-flex ml-auto">
                <span class="font-weight-bold font-large-2 ml-1">
                  <span
                      :class="(movieDetail.rating_kp < 5) ? 'text-danger' : (movieDetail.rating_kp < 7) ? 'text-gray' : 'text-success'"
                  >
                    {{ ratingFmt(movieDetail.rating_kp) }}
                  </span>
                  <div class="small">{{ movieDetail.votes_kp }}</div>
                </span>
                <span class="font-weight-bold font-large-1 ml-1">
                  <span
                      :class="(movieDetail.rating_imdb < 5) ? 'text-danger' : (movieDetail.rating_imdb < 7) ? 'text-gray' : 'text-success'"
                  >
                    {{ ratingFmt(movieDetail.rating_imdb) }}
                  </span>
                  <div class="small">{{ movieDetail.votes_imdb }}</div>
                </span>
              </div>
            </h1>

            <b-row>
              <b-col
                  cols="12"
                  md="3"
                  class="d-flex align-items-baseline justify-content-center mb-2 mb-md-0"
              >
                <div class="d-flex align-items-center justify-content-center">
                  <b-img
                      :src="movieDetail.poster"
                      alt="Постер"
                      class="product-img"
                      fluid
                  />
                </div>
              </b-col>
              <b-col
                  cols="12"
                  md="9"
                  class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
              >
                <b-card
                    :title="this.movieType(movieDetail.type)"
                    class="col-md-12 col-12"
                >
                  <b-overlay
                      :show="isLoadingMovie"
                      rounded="sm"
                  >
                    <b-row>
                      <b-col md="12">
                        <table class="mt-2 mt-xl-0 w-100">
                          <tr>
                            <th class="pb-50">
                              <feather-icon
                                  icon="UserIcon"
                                  class="mr-75"
                              />
                              <span class="font-weight-bold">Слоган</span>
                            </th>
                            <td class="pb-50">
                              {{ movieDetail.tagline }}
                            </td>
                          </tr>
                          <tr>
                            <th class="pb-50">
                              <feather-icon
                                  icon="CheckIcon"
                                  class="mr-75"
                              />
                              <span class="font-weight-bold">Страны</span>
                            </th>
                            <td class="pb-50 text-capitalize">
                              {{ movieDetail.countries }}
                            </td>
                          </tr>
                          <tr>
                            <th class="pb-50">
                              <feather-icon
                                  icon="StarIcon"
                                  class="mr-75"
                              />
                              <span class="font-weight-bold">КП</span>
                            </th>
                            <td class="pb-50 text-capitalize">
                              <b-link :href="`https://www.kinopoisk.ru/film/${movieDetail.kp_id}/`">
                                {{ movieDetail.rating_kp }} <span class="small">{{ movieDetail.votes_kp }}</span>
                              </b-link>
                            </td>
                          </tr>
                          <tr>
                            <th class="pb-50">
                              <feather-icon
                                  icon="FlagIcon"
                                  class="mr-75"
                              />
                              <span class="font-weight-bold">IMDB</span>
                            </th>
                            <td class="pb-50">
                              <b-link :href="`https://www.imdb.com/title/${movieDetail.imdb_id}/`">
                                {{ movieDetail.rating_imdb }} <span class="small">{{ movieDetail.votes_imdb }}</span>
                              </b-link>
                            </td>
                          </tr>
                        </table>

                        <div class="mb-1">
                          <b-button variant="primary" class="mr-1"
                                    @click="importMovie"
                          >
                            <feather-icon
                                icon="TvIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Инфо (КП Unoff)</span>
                          </b-button>
                          <b-button variant="primary" class="mr-1"
                                    @click="importMovieKpDev"
                          >
                            <feather-icon
                                icon="VideoIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Инфо (КП.dev)</span>
                          </b-button>
                          <b-button
                              @click="importStaff"
                          >
                            <feather-icon
                                icon="UsersIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Персоны</span>
                          </b-button>
                        </div>

                        <b-tabs
                            pills
                        >
                          <!-- Tab: Актеры -->
                          <b-tab active>
                            <template #title>
                              <feather-icon
                                  icon="UserIcon"
                                  size="16"
                                  class="mr-0 mr-sm-50"
                              />
                              <span class="d-none d-sm-inline">Актеры</span>
                            </template>

                            <div class="my-1 py-25">
                              <person-row
                                  v-for="actor in movieDetail.actors"
                                  :key="actor.staffId"
                                  :person="actor"
                                  @refresh="refreshData"
                                  @block="blockData"
                              ></person-row>
                            </div>
                          </b-tab>

                          <!-- Tab: Режиссеры -->
                          <b-tab>
                            <template #title>
                              <feather-icon
                                  icon="InfoIcon"
                                  size="16"
                                  class="mr-0 mr-sm-50"
                              />
                              <span class="d-none d-sm-inline">Режиссеры</span>
                            </template>

                            <div class="my-1 py-25">
                              <person-row
                                  v-for="director in movieDetail.directors"
                                  :key="director.staffId"
                                  :person="director"
                                  @refresh="refreshData"
                                  @block="blockData"
                              ></person-row>
                            </div>
                          </b-tab>
                          <!-- Tab: Вопросы -->
                          <b-tab>
                            <template #title>
                              <feather-icon
                                  icon="Share2Icon"
                                  size="16"
                                  class="mr-0 mr-sm-50"
                              />
                              <span class="d-none d-sm-inline">Вопросы</span>
                            </template>

                            <div class="my-1 py-25">
                            <span
                                v-for="theme in movieDetail.themes"
                                :key="theme.id"
                            >
                              <b-button
                                  v-if="theme.question"
                                  :to="{ name: 'question-edit', params: { id: theme.question.id } }"
                                  variant="success"
                                  class="mr-75 mb-1"
                              >
                                  {{ theme.name }}
                              </b-button>
                              <b-button
                                  v-else-if="theme.template"
                                  :to="{ name: theme.template, params: { movie_id: movieDetail.id } }"
                                  variant="warning"
                                  class="mr-75 mb-1"
                              >
                                  {{ theme.name }}
                              </b-button>
                            </span>
                            </div>
                          </b-tab>
                        </b-tabs>


                        <!-- Product Description -->
                        <!--                      <b-card-text>{{ movieDetail.description }}</b-card-text>-->
                      </b-col>
                    </b-row>
                  </b-overlay>
                </b-card>
              </b-col>
            </b-row>

            <!-- eslint-disable vue/no-v-html -->
            <div
                class="blog-content"
                v-html="movieDetail.description"
            />

            <!-- user commnets -->
            <b-media
                v-for="user in blogDetail.blog.UserComment"
                :key="user.avatar"
                no-body
            >
              <b-media-aside>
                <b-avatar
                    size="60"
                    :src="user.avatar"
                />
              </b-media-aside>
              <b-media-body>
                <h6 class="font-weight-bolder">
                  {{ user.fullName }}
                </h6>
                <b-card-text>
                  {{ user.comment }}
                </b-card-text>
              </b-media-body>
            </b-media>
            <!-- eslint-enable -->
            <hr class="my-2">

            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center mr-1">
                  <b-link class="mr-50">
                    <feather-icon
                        icon="MessageSquareIcon"
                        size="21"
                        class="text-body"
                    />
                  </b-link>
                  <b-link>
                    <div class="text-body">
                      {{ kFormatter(blogDetail.blog.comments) }}
                    </div>
                  </b-link>
                </div>
                <div class="d-flex align-items-center">
                  <b-link class="mr-50">
                    <feather-icon
                        size="21"
                        icon="BookmarkIcon"
                        class="text-body"
                    />
                  </b-link>
                  <b-link>
                    <div class="text-body">
                      {{ kFormatter(blogDetail.blog.bookmarked) }}
                    </div>
                  </b-link>
                </div>
              </div>

              <!-- dropdown -->
              <div class="blog-detail-share">
                <b-dropdown
                    variant="link"
                    toggle-class="p-0"
                    no-caret
                    right
                >
                  <template #button-content>
                    <feather-icon
                        size="21"
                        icon="Share2Icon"
                        class="text-body"
                    />
                  </template>
                  <b-dropdown-item
                      v-for="icon in socialShareIcons"
                      :key="icon"
                      href="#"
                  >
                    <feather-icon
                        :icon="icon"
                        size="18"
                    />
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <!--/ dropdown -->
            </div>
          </b-card>
        </b-col>
        <!--/ blogs -->
      </b-row>
      <!--/ blogs -->
    </div>
    <!--/ content -->

    <!-- sidebar -->
    <div
        slot="sidebar"
        class="blog-sidebar py-2 py-lg-0"
    >
      <!-- Сиквелы/Приквелы -->
      <div class="blog-recent-posts mt-3" v-if="sequels">
        <h6 class="section-label mb-75">
          Сиквелы/Приквелы
        </h6>
        <b-media
            v-for="(movie,index) in sequels"
            :key="movie.filmId"
            no-body
            :class="index? 'mt-2':''"
        >
          <b-media-aside class="mr-2">
            <b-link>
              <b-img
                  :src="movie.posterUrlPreview"
                  width="100"
                  rounded
                  height="70"
              />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="blog-recent-post-title">
              <b-link class="text-body-heading">
                <feather-icon
                    :icon="sequelIcon(movie.relationType)"
                    class="mr-25"
                />
                {{ movie.nameRu }}
              </b-link>
            </h6>
            <span class="text-muted mb-0" v-if="movie.db">
              {{ formatDate(movie.db.release_date_ru) }}
            </span>

            <div class="text-nowrap">
              <b-button
                  v-if="!movie.db"
                  variant="success"
                  class="btn-icon"
                  size="sm"
                  @click="addMovie(movie)"
                  :disabled="movie.loading"
              >
                <feather-icon icon="UserPlusIcon"/>
              </b-button>
              <b-button
                  v-else
                  :to="{name: 'movie-view', params: { id: movie.db.id }}"
                  variant="primary"
                  class="btn-icon"
                  size="sm"
              >
                <feather-icon icon="ArrowRightCircleIcon"/>
              </b-button>
              <b-button
                  :href="`https://kinopoisk.ru/film/${movie.filmId}`"
                  variant="warning"
                  class="btn-icon ml-1"
                  size="sm"
              >
                <feather-icon icon="CastIcon"/>
              </b-button>
            </div>
          </b-media-body>
        </b-media>
      </div>
      <!--/ Сиквелы/Приквелы -->
    </div>
  </content-with-sidebar>
</template>

<script>
import {
  BFormInput,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BLink,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BRow,
  BCol,
  BBadge,
  BCardText,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormTextarea,
  BFormCheckbox,
  BButton,
  BTab,
  BTabs,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {kFormatter, formatDate, ratingFmt} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import router from "@/router";
import store from "@/store";
import movieStoreModule from "@/views/database/movieStoreModule";
import {onUnmounted, ref} from "@vue/composition-api";
import PersonRow from "@/views/database/PersonRow";

export default {
  components: {
    PersonRow,
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BBadge,
    BCardText,
    BDropdown,
    BForm,
    BDropdownItem,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BTab,
    BTabs,
    BSpinner,
    BOverlay,
    ContentWithSidebar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      commentCheckmark: '',
      blogDetail: [],
      socialShareIcons: [
        'GithubIcon',
        'GitlabIcon',
        'FacebookIcon',
        'TwitterIcon',
        'LinkedinIcon',
      ],
    }
  },
  created() {
    this.$http.get('/blog/list/data/detail').then(res => {
      this.blogDetail = res.data
    })
  },
  methods: {
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
    sequelIcon(type) {
      if (type === 'REMAKE') return 'RefreshCcwIcon'
      if (type === 'PREQUEL') return 'ArrowLeftCircleIcon'
      if (type === 'SEQUEL') return 'ArrowRightCircleIcon'
      return 'LinkIcon'
    },
    movieType(type) {
      if (type === 'FILM') return 'Фильм'
      if (type === 'TV_SHOW') return 'ТВ-Шоу'
      if (type === 'TV_SERIES') return 'Сериал'
      if (type === 'MINI_SERIES') return 'Мини-сериал'
      if (type === 'VIDEO') return 'Видео'
      return 'Неизвестный тип'
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'movies'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, movieStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const movieDetail = ref(null)
    const sequels = ref([])
    const isLoadingMovie = ref(false)
    const isLoadingStaff = ref(false)
    const disabledMovieId = ref(null)

    const fetchSequels = () => {
      let id = router.currentRoute.params.id
      store.dispatch('movies/fetchMovieSequels', {id})
          .then(response => {
            sequels.value = response.data
          })
    }

    const fetchMovieData = () => {
      let id = router.currentRoute.params.id
      store.dispatch('movies/fetchMovieDetails', {id}).then(res => {
        movieDetail.value = res.data.data
      })
    }

    const refreshData = () => {
      blockData()
      fetchMovieData()
      unblockData()
    }

    const blockData = () => {
      isLoadingMovie.value = true
    }

    const unblockData = () => {
      isLoadingMovie.value = false
    }

    fetchMovieData()
    fetchSequels()

    const addMovie = (movie) => {
      movie.loading = true
      blockData()
      store.dispatch(`movies/createMovie`, {kp_id: movie.filmId, title_ru: movie.nameRu}).then(response => {
        fetchSequels()
        unblockData()
      })
    }

    const importMovie = () => {
      blockData()
      store.dispatch('movies/loadMovieFromKP', {
        id: router.currentRoute.params.id
      }).then(response => {
        movieDetail.value = response.data.data
        unblockData()
      })
    }

    const importMovieKpDev = () => {
      blockData()
      store.dispatch('movies/loadMovieFromKPDev', {
        id: router.currentRoute.params.id
      }).then(response => {
        movieDetail.value = response.data.data
        unblockData()
      })
    }

    const importStaff = () => {
      blockData()
      store.dispatch('movies/loadStaffFromKP', {
        id: router.currentRoute.params.id
      }).then(response => {
        movieDetail.value = response.data.data
        unblockData()
      })
    }

    return {
      importMovie,
      importMovieKpDev,
      importStaff,
      addMovie,
      formatDate,
      ratingFmt,
      movieDetail,
      sequels,
      isLoadingMovie,
      isLoadingStaff,
      disabledMovieId,
      refreshData,
      blockData,
      unblockData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
